import axios from 'axios';

import TaskTodo from '@/models/task-todo.model';

import CreateTaskTodoRequest from '@/requests/create-task-todo.request';
import UpdateTaskTodoRequest from '@/requests/update-task-todo.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class TaskTodoService {
	public static async get(taskId: number): Promise<TaskTodo[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/tasks/${taskId}/task-todos`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at heinta allar todos til uppgávuna við id ${taskId}`,
					error
				);

				Toast.error('Tað eydnaðist ikki at heinta uppgávulistan');

				return false;
			});
	}

	public static async create(createTaskTodoRequest: CreateTaskTodoRequest): Promise<TaskTodo> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/task-todos`, createTaskTodoRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna todo', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateTaskTodoRequest: UpdateTaskTodoRequest): Promise<TaskTodo> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/task-todos/${id}`, updateTaskTodoRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta todo við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/task-todos/${id}`)
			.then((response) => {
				Toast.success('Uppgáva strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika todo við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	// task-todos/{taskTodo}/toggle
	public static async toggle(id: number): Promise<ServerResponse> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/task-todos/${id}/toggle`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at toggle todo við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}


import { defineComponent, nextTick, ref } from 'vue';

import Store from '@/store';

import TaskTodoService from '@/services/task-todo.service';

import Todo from '@/components/task/Todo.vue';

export default defineComponent({
	props: ['todos'],

	components: {
		Todo,
	},

	setup() {
		const task = Store.get('task');

		const title = ref('');

		const input = ref(null as HTMLInputElement | null);

		const isAdding = ref(false);

		function toggleAdding() {
			isAdding.value = true;

			nextTick(() => {
				input.value && input.value.focus();
			});
		}

		async function add() {
			if (title.value.length === 0) {
				return;
			}

			await TaskTodoService.create({ title: title.value, task_id: task.value.id }).then((response) => {
				if (response) {
					title.value = '';

					if (!task.value.todos) {
						task.value.todos = [];
					}

					if (response) {
						task.value.todos = [...task.value.todos, response];
					}
				}
			});
		}

		return {
			// data
			input,
			isAdding,
			title,

			// functions
			toggleAdding,
			add,
		};
	},
});


import { defineComponent, nextTick, Ref, ref, watch } from 'vue';

import Store from '@/store';

import SVG from '@/components/SVG.vue';
import TaskCommentService from '@/services/task-comment.service';
import User from '@/models/user.model';

export default defineComponent({
	props: ['comments'],

	components: {
		SVG,
	},

	setup(props) {
		const task = Store.get('task');

		const users = Store.get('users');

		const comment = ref('');
		const commentsContainer: Ref = ref(null);

		// Scroll to the bottom of the comments container
		function scrollToBottom() {
			nextTick(() => {
				commentsContainer.value.scrollTo(0, commentsContainer.value.scrollHeight);
			});
		}

		watch(
			() => props.comments,
			async () => {
				scrollToBottom();
			}
		);

		// Get a user by id
		function getUser(userId: number) {
			const user = users.value.find((user: User) => user.id == userId);

			return user;
		}

		function timeSince(date: string) {
			const createdAt = new Date(date);
			const since = createdAt.diff(new Date());

			if (since.minutes === 0) {
				return 'Júst nú';
			}

			if (since.hours === 0) {
				return `${since.minutes} ${since.minutes > 1 ? 'minuttir' : 'minut'} síðani`;
			}

			if (since.hours < 3) {
				return `${since.hours} ${since.hours > 1 ? 'tímar' : 'tími'} síðani`;
			}

			if (since.days === 0) {
				return `Í dag kl. ${createdAt.format('%H:%M')}`;
			}

			return `${createdAt.format('%d. %mn3. %y')}`;
		}

		function getUserFullName(userId: number) {
			const user = getUser(userId);

			return `${user.first_name} ${user.last_name}`;
		}

		function getUserInitials(userId: number) {
			const user = getUser(userId);

			return `${user.first_name[0]}${user.last_name[0]}`;
		}

		async function postComment() {
			await TaskCommentService.create({ body: comment.value, task_id: task.value.id }).then((response) => {
				if (response) {
					comment.value = '';

					if (!task.value.comments) {
						task.value.comments = [];
					}

					task.value.comments = [...task.value.comments, response];

					nextTick(() => {
						scrollToBottom();
					});
				}
			});
		}

		return {
			// data
			comment,
			commentsContainer,

			// functions
			timeSince,
			getUserFullName,
			getUserInitials,
			postComment,
		};
	},
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = ["href", "download"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Group = _resolveComponent("Group")!
  const _component_EditTaskOverlay = _resolveComponent("EditTaskOverlay")!
  const _component_AddTaskOverlay = _resolveComponent("AddTaskOverlay")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Loader, { class: "w-8 h-8 text-blue" })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, index) => {
              return (_openBlock(), _createBlock(_component_Group, {
                key: index,
                group: group,
                everyTask: _ctx.allTasks,
                onToggleSelected: _ctx.toggleSelected,
                onOpenOverlay: _ctx.openOverlay,
                onUpdateTitle: ($event: any) => (group.title = $event),
                onDelete: _ctx.deleteGroup
              }, null, 8, ["group", "everyTask", "onToggleSelected", "onOpenOverlay", "onUpdateTitle", "onDelete"]))
            }), 128)),
            (_ctx.$user.can('create tasks'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addGroup && _ctx.addGroup(...args))),
                    class: "mt-6 text-gray-500 cursor-pointer"
                  }, "+ Nýggjan bólk")
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              ref: "csv",
              href: `data:text/csv;charset=utf-8,${_ctx.csvData}`,
              download: `tasks-${new Date().getTime()}.csv`,
              class: "hidden"
            }, null, 8, _hoisted_4),
            _createVNode(_component_EditTaskOverlay, {
              active: _ctx.overlay.active,
              "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.overlay.active) = $event)),
              groups: _ctx.groups
            }, null, 8, ["active", "groups"]),
            _createVNode(_component_AddTaskOverlay, {
              active: _ctx.addOverlay,
              "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addOverlay) = $event)),
              projectId: _ctx.project.id,
              groups: _ctx.groups
            }, null, 8, ["active", "projectId", "groups"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
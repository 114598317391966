
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';

import Store from '@/store';

import TaskTodoService from '@/services/task-todo.service';

import SVG from '@/components/SVG.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import UserInputSingle from '../form/UserInputSingle.vue';
import TaskTodo from '@/models/task-todo.model';

export default defineComponent({
	props: ['todo'],

	components: {
		SVG,
		Checkbox,
		UserInputSingle,
	},

	setup(props) {
		const task = Store.get('task');

		const title = ref(props.todo.title);

		const responsibleUser = ref(props.todo.responsible_user_id ? props.todo.responsible_user_id : null);

		const showUser = ref(!!props.todo.responsible_user_id);

		const checked = ref(!!props.todo.completed_at);

		const contextMenu = reactive({
			active: false,
		});

		const editTitle = reactive({
			active: false,
		});

		// Watch props
		watch(props, (propsValue) => {
			checked.value = !!propsValue.todo.completed_at;

			if (responsibleUser.value !== propsValue.todo.responsible_user_id) {
				responsibleUser.value = propsValue.todo.responsible_user_id;
			}
		});

		// Update responsible user on change
		watch(responsibleUser, async (value: number) => {
			await TaskTodoService.update(props.todo.id, {
				responsible_user_id: value,
			}).then((response) => {
				if (response) {
					task.value.todos.find((todo: TaskTodo) => todo.id == props.todo.id).responsible_user_id = value;
				}
			});
		});

		async function remove() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			await TaskTodoService.delete(props.todo.id).then((response) => {
				if (response) {
					task.value.todos = task.value.todos.filter((todo: TaskTodo) => todo.id != props.todo.id);
				}
			});
		}

		async function updateTitle() {
			await TaskTodoService.update(props.todo.id, {
				title: title.value,
			}).then((response) => {
				if (response) {
					task.value.todos.find((todo: TaskTodo) => todo.id == props.todo.id).title = title.value;
				}
			});
		}

		async function toggle(isChecked: boolean) {
			const completedAt = isChecked ? new Date().format('%y-%m-%d %H:%M:%S') : null;

			await TaskTodoService.toggle(props.todo.id).then((response) => {
				if (response) {
					task.value.todos.find((todo: TaskTodo) => todo.id == props.todo.id).completed_at = completedAt;
				}
			});
		}

		function handleEditTitle(target: Element) {
			const currentTitle = editTitle.active;

			editTitle.active = true;
			if (!currentTitle) {
				nextTick(() => target.closest('.input-wrapper')?.querySelector('input')?.focus());
			}
		}

		return {
			// data
			title,
			checked,
			contextMenu,
			responsibleUser,
			showUser,
			editTitle,

			// functions
			remove,
			updateTitle,
			toggle,
			handleEditTitle,
		};
	},
});

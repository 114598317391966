
import { computed, defineComponent, onMounted, reactive, Ref, ref } from 'vue';
import _sortBy from 'lodash/sortBy';
import { useRoute } from 'vue-router';

import Store from '@/store';

import useEmitter from '@/compostables/useEmitter';

import ProjectService from '@/services/project.service';
import TaskGroupService from '@/services/task-group.service';
import TaskService from '@/services/task.service';

import Task from '@/models/task.model';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import EditTaskOverlay from '@/components/project/tasks/EditOverlay.vue';
import AddTaskOverlay from '@/components/project/tasks/AddOverlay.vue';
import Group from '@/components/project/tasks/Group.vue';
import TaskStatus from '@/models/task-status.model';
import User from '@/models/user.model';
import Project from '@/models/project.model';

interface GroupTask {
	id: number;
	title: string;
	tasks: Task[];
}

export default defineComponent({
	components: {
		Layout,
		Loader,
		EditTaskOverlay,
		AddTaskOverlay,
		Group,
	},

	setup() {
		const route = useRoute();
		const emitter = useEmitter();

		const contextMenu = Store.get('header.contextMenu');

		const project: Ref<Project | null> = ref(null);
		const loading = ref(true);
		// when duplicating a task, 'duplicate' holds the copy of the task
		const csv: Ref = ref('csv');
		const groups: Ref<GroupTask[]> = ref([]);
		const allTasks: Ref<Task[]> = ref([]);
		const selectedTasks: Ref<Task[]> = ref([]);

		const addOverlay = ref(false);

		const overlay = reactive({
			active: false,
		});

		// computed
		const csvData = computed(() => {
			let tasks = selectedTasks.value;

			// if no tasks are selected, select all tasks
			if (groups.value && !tasks.length) {
				tasks = groups.value[0]?.tasks;
			}

			if (!tasks) {
				return '';
			}

			let csvData = 'title,description,responsible_user,expected_start,expected_end,status\r\n';

			for (const task of tasks.map((task: Task) => [
				task.title,
				task.description,
				task.responsible?.email,
				task.expected_start,
				task.expected_end,
				task.status?.title,
			])) {
				csvData += `${task.join(',')}\r\n`;
			}

			return encodeURI(csvData);
		});

		// On Mounted
		onMounted(async () => {
			// Add Header Context Menu
			Store.set('header.contextMenu', {
				type: 'task',
				hasSelected: computed(() => selectedTasks.value?.length),
			});

			Store.set('header.nav', [
				{
					title: 'Listi',
					name: 'ProjectTasks',
				},
				{
					title: 'Kanban',
					name: 'ProjectTasksCards',
				},
			]);

			Store.set('header.options', [
				{
					title: 'Stovna uppgávu',
					action: 'task:add',
				},
			]);

			// Load Project & Task Groups
			await ProjectService.show(Number(route.params.id)).then((response) => {
				if (response) {
					project.value = response;
				}
			});

			await TaskGroupService.get(Number(route.params.id)).then((response) => {
				// Add Task Groups to 'group'
				if (response) {
					for (const group of response) {
						groups.value = [
							...groups.value,
							{
								id: group.id,
								title: group.title,
								tasks: [],
							},
						];
					}

					Store.set('task.groups', groups.value);
				}
			});

			// Insert Tasks into Groups
			allTasks.value = project.value?.tasks || [];

			for (const task of allTasks.value) {
				const group = groups.value.find((group: GroupTask) => group.id == task.task_group_id);

				if (!group) {
					continue;
				}

				group.tasks = _sortBy([...group.tasks, task], 'group_index');
			}

			loading.value = false;

			// If coming from search, open the overlay with the chosen task (from task_id)
			if (route.query?.task_id) {
				const task = project.value?.tasks.filter((task: Task) => task.id === Number(route.query.task_id));

				if (task) {
					openOverlay(task[0]);
				}

				window.history.pushState({}, document.title, window.location.pathname);
			}

			// Open Task overlay
			emitter.on('task:add', () => {
				addOverlay.value = true;
			});

			// Close Overlay on escape
			emitter.on('escape', () => {
				overlay.active = false;
			});

			// Bulk Status Update
			emitter.on('bulk:status', async (status: TaskStatus) => {
				if (!selectedTasks.value?.length) {
					return;
				}

				await TaskService.bulkUpdate({
					task_ids: selectedTasks.value.map((task: Task) => task.id),
					task_status_id: status.id,
				}).then((response) => {
					if (response) {
						for (const task of selectedTasks.value) {
							task.status = status;
							task.task_status_id = status.id;
						}
						contextMenu.value.active = false;
					}
				});
			});

			// Bulk Responsible Update
			emitter.on('bulk:user', async (user: User) => {
				if (!selectedTasks.value?.length) {
					return;
				}

				await TaskService.bulkUpdate({
					task_ids: selectedTasks.value.map((task: Task) => task.id),
					responsible_user_id: user.id,
				}).then((response) => {
					if (response) {
						for (const task of selectedTasks.value) {
							task.responsible_user_id = user.id;
						}
						contextMenu.value.active = false;
					}
				});
			});

			// Bulk Expected Start
			emitter.on('bulk:expected-start', async (value: Date | null) => {
				if (!selectedTasks.value?.length) {
					return;
				}

				await TaskService.bulkUpdate({
					task_ids: selectedTasks.value.map((task: Task) => task.id),
					expected_start: value?.format('%y-%m-%d'),
				}).then((response) => {
					if (response) {
						for (const task of selectedTasks.value) {
							task.expected_start = value?.format('%y-%m-%d');
						}
						contextMenu.value.active = false;
					}
				});
			});

			// Bulk Expected End
			emitter.on('bulk:expected-end', async (value: Date | null) => {
				if (!selectedTasks.value?.length) {
					return;
				}

				await TaskService.bulkUpdate({
					task_ids: selectedTasks.value.map((task: Task) => task.id),
					expected_end: value?.format('%y-%m-%d'),
				}).then((response) => {
					if (response) {
						for (const task of selectedTasks.value) {
							task.expected_end = value?.format('%y-%m-%d');
						}
						contextMenu.value.active = false;
					}
				});
			});

			// Bulk Remove
			emitter.on('bulk:remove', async () => {
				if (!confirm('Ert tú vís/ur?')) {
					return;
				}

				if (!selectedTasks.value?.length) {
					return;
				}

				const task_ids = selectedTasks.value.map((task: Task) => task.id);

				await TaskService.bulkRemove({
					task_ids,
				}).then(async (response) => {
					if (response) {
						groups.value = groups.value.map((group: GroupTask) => ({
							...group,
							tasks: group.tasks.filter((task: Task) => !task_ids.includes(task.id)),
						}));

						contextMenu.value.active = false;
					}
				});
			});

			// Export as CSV
			emitter.on('export-csv', async () => {
				csv.value.click();
				contextMenu.value.active = false;
			});
		});

		// Open the Task overlay
		function openOverlay(task: Task) {
			const user = Store.get('user');

			if (!user.value.can('update tasks')) {
				return;
			}

			Store.set('task', task);

			overlay.active = true;
		}

		// Toggle selected tasks
		function toggleSelected(tasks: Task[]) {
			const checkedTasks: Task[] = [];

			tasks.forEach((task) => {
				if (task.checked) {
					checkedTasks.push(task);
				}
			});

			const clearGroup = selectedTasks.value.filter(
				(task: Task) => task.task_group_id !== tasks[0].task_group_id
			);

			selectedTasks.value = [...clearGroup, ...checkedTasks];
		}

		// Add a new Group
		async function addGroup() {
			if (project.value) {
				await TaskGroupService.create({
					title: 'Nýggjur bólkur',
					project_id: project.value.id,
				}).then((response) => {
					if (response) {
						groups.value = [
							...groups.value,
							{
								id: response.id,
								title: response.title,
								tasks: [],
							},
						];
					}
				});
			}
		}

		function deleteGroup(groupId: number) {
			groups.value = groups.value.filter((group: GroupTask) => group.id != groupId);
		}

		return {
			// data
			groups,
			project,
			csv,
			loading,
			overlay,
			csvData,
			allTasks,
			addOverlay,

			// functions
			openOverlay,
			addGroup,
			deleteGroup,
			toggleSelected,
		};
	},
});

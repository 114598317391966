import axios from 'axios';

import TaskComment from '@/models/task-comment.model';

import CreateTaskCommentRequest from '@/requests/create-task-comment.request';
import UpdateTaskCommentRequest from '@/requests/update-task-comment.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class TaskCommentService {
	public static async get(taskId: number): Promise<TaskComment[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/tasks/${taskId}/comments`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at heinta allar viðmerkingarnar til uppgávuna við id ${taskId}`,
					error
				);

				Toast.error('Tað eydnaðist ikki at heinta viðmerkingar');

				return false;
			});
	}

	public static async create(createTaskCommentRequest: CreateTaskCommentRequest): Promise<TaskComment> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/task-comments`, createTaskCommentRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna viðmerkingina', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateTaskCommentRequest: UpdateTaskCommentRequest): Promise<TaskComment> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/task-comments/${id}`, updateTaskCommentRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta viðmerkingina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/task-comments/${id}`)
			.then((response) => {
				Toast.success('Viðmerking strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika viðmerkingina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}


import { computed, defineComponent, onMounted, onUnmounted, reactive, Ref, ref, watch } from 'vue';
import _has from 'lodash/has';

import Store from '@/store';

import TaskService from '@/services/task.service';

import SVG from '@/components/SVG.vue';
import Editor from '@/components/form/Editor.vue';
import Select from '@/components/form/Select.vue';
import StatusSelect from '@/components/form/StatusSelect.vue';
import UserInputSingle from '@/components/form/UserInputSingle.vue';
import DateInput from '@/components/form/DateInput.vue';
import Todos from '@/components/task/Todos.vue';
import Comments from '@/components/task/Comments.vue';
import Task from '@/models/task.model';
import TaskStatus from '@/models/task-status.model';
import User from '@/models/user.model';
import Meeting from '@/models/meeting.model';

interface Form {
	title: string;
	description: string;
	status: TaskStatus | number;
	responsible: number | null;
	expectedStart: Date | null;
	expectedEnd: Date | null;
	point: number;
	group: number | null;
}

export default defineComponent({
	props: ['active', 'groups'],

	emits: ['update:active'],

	components: {
		SVG,
		Editor,
		StatusSelect,
		UserInputSingle,
		DateInput,
		Select,
		Todos,
		Comments,
	},

	setup(props) {
		const task = Store.get('task');
		const users = Store.get('users');
		const meetings = Store.get('meetings');

		const formIsDirty = ref(false);

		let canEdit = false;

		const container: Ref = ref(null);

		const loading = ref(false);

		const form: Form = reactive({
			title: '',
			description: '',
			status: 1,
			responsible: null,
			expectedStart: null,
			expectedEnd: null,
			point: 1,
			group: null,
		});

		const updatedAt = computed(() => {
			const date = new Date(task.value?.updated_at);

			const createdAt = new Date(date);
			const since = createdAt.diff(new Date());

			if (since.minutes === 0) {
				return 'Júst nú';
			}

			if (since.hours === 0) {
				return `${since.minutes} ${since.minutes > 1 ? 'minuttir' : 'minut'} síðani`;
			}

			if (since.hours < 3) {
				return `${since.hours} ${since.hours > 1 ? 'tímar' : 'tími'} síðani`;
			}

			if (since.days === 0) {
				return `Í dag kl. ${createdAt.format('%H:%M')}`;
			}

			return `${createdAt.format('%d. %mn3. %y')}`;
		});

		function populateForm(task: Task) {
			form.title = task.title;
			form.description = task.description || '';
			form.status = task.task_status_id || 1;
			form.responsible = task.responsible_user_id || null;
			form.expectedStart = task.expected_start ? new Date(task.expected_start) : null;
			form.expectedEnd = task.expected_end ? new Date(task.expected_end) : null;
			form.point = task.point;
			form.group = task.task_group_id || null;
		}

		// get user's full name
		function getFullName(userId: number) {
			if (!userId || !users.value) {
				return '';
			}

			const user = users.value.find((user: User) => user.id === userId);

			if (user) {
				return `${user.first_name} ${user.last_name}`;
			}

			return '';
		}

		// get meeting
		function getMeetingName(meetingId: number) {
			console.log(meetings.value);
			if (!meetingId || !meetings.value) {
				return '';
			}

			const meeting = meetings.value.find((meeting: Meeting) => meeting.id === meetingId);

			if (meeting) {
				return meeting.title;
			}

			return '';
		}

		// computed
		function formatDate(date: Date) {
			if (!date) {
				return '';
			}

			return new Date(date).format('%-d. %mn3. %y');
		}

		onMounted(() => {
			window.onbeforeunload = function () {
				if (formIsDirty.value) {
					return 'Ert tú vís/ur?';
				}
			};

			window.onkeydown = function (e: KeyboardEvent) {
				if (!props.active) {
					return;
				}

				if (e.ctrlKey && e.key == 's') {
					e.preventDefault();

					save();
				}
			};

			if (!task.value) {
				return;
			}

			populateForm(task.value);
		});

		onUnmounted(() => {
			window.onbeforeunload = null;
		});

		// Expected end cannot be before expected start
		watch(
			() => form.expectedStart,
			(value) => {
				if (!form.expectedEnd) {
					return;
				}

				if (value !== null && value > form.expectedEnd) {
					form.expectedEnd = form.expectedStart;
				}
			}
		);

		watch(task, (value) => {
			console.log(value);
		});

		// save the changes to the task
		async function save() {
			if (!task.value?.id) {
				return;
			}

			if (!formIsDirty.value) {
				return;
			}

			if (task.value?.id && task.value?.project_id) {
				let statusId = form.status;

				if (typeof statusId !== 'number' && _has(statusId, 'id')) {
					statusId = statusId.id;
				}

				loading.value = true;

				await TaskService.update(task.value.id, {
					title: form.title,
					project_id: task.value.project_id,
					description: form.description,
					expected_start: form.expectedStart ? form.expectedStart.format('%y-%m-%d') : null,
					expected_end: form.expectedEnd ? form.expectedEnd.format('%y-%m-%d') : null,
					point: form.point,
					task_group_id: form.group,
					responsible_user_id: form.responsible,
					task_status_id: Number(statusId),
				}).finally(() => {
					formIsDirty.value = false;
					loading.value = false;
				});
			}
		}

		// Watch active state
		watch(
			() => props.active,
			(value) => {
				if (value) {
					formIsDirty.value = false;

					setTimeout(() => {
						canEdit = true;
					}, 200);
				}

				// Save when closing the modal
				if (!value) {
					save();

					canEdit = false;
				}
			}
		);

		// When the task is changed, add the values to the form
		watch(task, (value) => {
			if (!value) {
				return;
			}

			populateForm(value);
		});

		watch(form, (value) => {
			if (!canEdit) {
				return;
			}

			if (!formIsDirty.value) {
				formIsDirty.value = true;
			}

			task.value.title = value.title;
			task.value.description = value.description;
			task.value.responsible_user_id = value.responsible || null;

			task.value.expected_start = value.expectedStart;
			task.value.expected_end = value.expectedEnd;
			task.value.point = value.point;
			task.value.task_group_id = value.group;
			task.value.status = value.status;
		});

		// Scroll to the top when opening the overlay
		watch(
			() => props.active,
			() => {
				container.value.scrollTo(0, 0);
			}
		);

		return {
			// data
			task,
			container,
			form,
			loading,

			// computed
			updatedAt,

			// functions
			close,
			getFullName,
			formatDate,
			getMeetingName,
		};
	},
});


import { defineComponent, onMounted, onUnmounted, reactive, Ref, ref, watch } from 'vue';
import _has from 'lodash/has';

import TaskService from '@/services/task.service';

import SVG from '@/components/SVG.vue';
import Editor from '@/components/form/Editor.vue';
import Select from '@/components/form/Select.vue';
import StatusSelect from '@/components/form/StatusSelect.vue';
import UserInputSingle from '@/components/form/UserInputSingle.vue';
import DateInput from '@/components/form/DateInput.vue';
import Store from '@/store';
import TaskStatus from '@/models/task-status.model';
import ProjectService from '@/services/project.service';

interface Form {
	title: string;
	description: string;
	status: TaskStatus | number;
	responsible: number | null;
	expectedStart: Date | null;
	expectedEnd: Date | null;
	point: number;
	group: number | null;
}

export default defineComponent({
	props: ['projectId', 'active', 'status', 'responsible', 'groups'],

	emits: ['update:active'],

	components: {
		SVG,
		Editor,
		StatusSelect,
		UserInputSingle,
		DateInput,
		Select,
	},

	setup(props) {
		const container: Ref = ref(null);

		const loading = ref(false);

		const form: Form = reactive({
			title: '',
			description: '',
			status: 0,
			responsible: null,
			expectedStart: null,
			expectedEnd: null,
			point: 1,
			group: null,
		});

		onMounted(() => {
			window.onkeydown = function (e: KeyboardEvent) {
				if (!props.active) {
					return;
				}

				if (e.ctrlKey && e.key === 's') {
					e.preventDefault();

					save();
				}
			};

			if (props.status) {
				form.status = props.status;
			}

			if (props.responsible) {
				form.responsible = props.responsible;
			}
		});

		onUnmounted(() => {
			window.onbeforeunload = null;
		});

		watch(props, (propsValue) => {
			if (propsValue.status) {
				form.status = propsValue.status;
			}

			if (propsValue.responsible) {
				form.responsible = props.responsible;
			}

			if (propsValue.groups.length) {
				form.group = propsValue.groups[0].id;
			}
		});

		// Watch active state
		watch(
			() => props.active,
			(value) => {
				// Save when closing the modal
				if (!value) {
					save();
					return;
				}

				const input = document.getElementById('titleInput');

				if (input) {
					input.focus();
				}
			}
		);

		// Expected end cannot be before expected start
		watch(
			() => form.expectedStart,
			(value) => {
				if (!form.expectedEnd) {
					return;
				}

				if (value !== null && value > form.expectedEnd) {
					form.expectedEnd = form.expectedStart;
				}
			}
		);

		// save the changes to the task
		async function save() {
			loading.value = true;

			if (props.projectId && form.title.length > 0) {
				let statusId = form.status;

				if (typeof statusId !== 'number' && _has(statusId, 'id')) {
					statusId = statusId.id;
				}

				await TaskService.create({
					title: form.title,
					project_id: props.projectId,
					description: form.description,
					expected_start: form.expectedStart ? form.expectedStart.format('%y-%m-%d') : null,
					expected_end: form.expectedEnd ? form.expectedEnd.format('%y-%m-%d') : null,
					point: form.point,
					task_group_id: form.group,
					responsible_user_id: form.responsible,
					task_status_id: Number(statusId),
				}).then(async (createResponse) => {
					if (createResponse) {
						await ProjectService.show(Number(props.projectId)).then((response) => {
							if (response) {
								Store.set('tasks', response.tasks);
							}
						});
					}
				});
			}

			form.title = '';
			form.description = '';
			form.status = 1;
			form.responsible = null;
			form.expectedStart = null;
			form.expectedEnd = null;
			form.point = 1;
			form.group = null;

			loading.value = false;
		}

		// Scroll to the top when opening the overlay
		watch(
			() => props.active,
			() => {
				container.value.scrollTo(0, 0);
			}
		);

		return {
			// data
			container,
			form,
			loading,

			// functions
			close,
		};
	},
});
